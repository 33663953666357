import React, { Component } from "react"
import R from "ramda"
import { Link } from "gatsby"

import "normalize.css"

import aceCss from "ace-css/css/ace.min.css"
import "css/main.css"

const nav = [
  {
    to: "/",
    children: "Home",
  },
  {
    to: "/practitioners/",
    children: "Practitioners",
  },
  {
    to: "/treatments/",
    children: "Treatments",
  },
  {
    to: "/contact/",
    children: "Contact",
  },
]

export default class extends Component {
  componentDidMount() {
    let Typekit
    const $script = require("scriptjs")
    $script("//use.typekit.net/fam5bpv.js", () => {
      try {
        Typekit.load()
      } catch (e) {}
    })
  }

  render() {
    return (
      <div id="top">
        <div className="site-header p2">
          <div className="container mx-auto sm-flex flex-wrap justify-between items-center">
            <Link to="/" className="block mx-auto sm-mx0 mb1 sm-mb0">
              <img
                src={require("css/logo.svg")}
                className="logo block mx-auto sm-mx0"
              />
            </Link>

            <nav className="mx-auto sm-mx0">
              <ul className="list-reset flex m0 nav">
                {R.map(
                  item => (
                    <li className="nav-item sm-ml2">
                      <Link {...item} className="white" />
                    </li>
                  ),
                  nav
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div>{this.props.children}</div>
      </div>
    )
  }
}
